import React, { MouseEvent, useEffect, useState } from 'react';

import { MdPerson } from 'react-icons/md';
import { FaEdit, FaPlus } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';

import { parseISO } from 'date-fns/esm';
import { format } from 'date-fns';

import { SectionCards, ContainerCard, CardButton } from './styles';
import { ModalCard } from './ModalCard';

import { useCards } from './CardContext';
import DefaultLoader from '../DefaultLoader';
import { useTableViewManager } from '~/context/tableViewManager';
import { getUserData } from '~/services/user';

const CardContent: React.FC = () => {
  const [enableExcluir, setEnableExcluir] = useState(false);

  const {
    showTable,
    queryClient,
    getFieldsCards,
    handleEditCard,
    showCards,
    codTelaProp,
    cardsFiltered,
    loadingCard,
    tipoFiltro,
    codUsuario,
    isOpenModal,
  } = useCards();

  const user = getUserData();

  const { findViewByCardClicked, onChangeCodCardSelected } =
    useTableViewManager();

  const handleCardButtonClick = async (card: any) => {
    onChangeCodCardSelected(card.cod_card);
    await findViewByCardClicked(card.cod_card, codTelaProp);

    const infoCard = {
      cod_card: card.cod_card,
      des_card: card.des_card,
      cor: card.cor,
      cor_texto: card.cor_texto,
      cod_card_fixado: card.cod_card_fixado,
      tipo_acesso: card.tipo_acesso,
      cod_usuario: card.cod_usuario,
    };

    queryClient.setQueryData(`infoCardAtivo_${codTelaProp}`, infoCard);

    await getFieldsCards(
      card.cod_card,
      true,
      card.des_card,
      card.cor,
      card.cor_texto,
      card.cod_card_fixado,
      card.tipo_acesso,
      false,
      card.cod_usuario,
    );
  };

  const handleEditButtonClick = async (card: any, event: MouseEvent) => {
    event.stopPropagation();
    handleEditCard(card);
    onChangeCodCardSelected(null);
  };

  useEffect(() => {
    if (isOpenModal) {
      const checkCodUsuario =
        codUsuario === 0 || !codUsuario ? user.id : codUsuario;
      setEnableExcluir(checkCodUsuario === user.id);
    }
  }, [codUsuario, isOpenModal, user.id]);

  return (
    <div
      style={{
        width: '90%',
        margin: '0 auto',
        marginTop: '30px',
        display: !showTable && showCards ? '' : 'none',
      }}
    >
      <SectionCards>
        {loadingCard && (
          <>
            <DefaultLoader />
          </>
        )}

        {!loadingCard &&
          cardsFiltered?.map((card) => (
            <ContainerCard key={card.cod_card}>
              <CardButton
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                  background: card.cor,
                }}
                key={card.cod_card}
                onClick={async () => {
                  await handleCardButtonClick(card);
                }}
              >
                <h3 title={card.des_card} className="text-stroke">
                  {card.des_card}
                </h3>
                <ul>
                  <li>
                    <MdPerson />
                    {card.usuario}
                  </li>
                  {card.dta_ult_acesso && (
                    <li>
                      <BiCalendar />

                      {format(
                        new Date(parseISO(card.dta_ult_acesso)),
                        'dd/MM/yyyy',
                      )}
                    </li>
                  )}
                </ul>
              </CardButton>

              <button
                type="button"
                className="btn-edit"
                onClick={(event) => handleEditButtonClick(card, event)}
              >
                {tipoFiltro === 0 ? <FaEdit /> : <FaPlus />}
              </button>

              {tipoFiltro === 0 && (
                <div>
                  {card.tipo_acesso === 0 && (
                    <span className="btn-edit2">PRIVADO</span>
                  )}
                  {card.tipo_acesso === 1 && (
                    <span className="btn-edit2">LOJA</span>
                  )}
                  {card.tipo_acesso === 2 && (
                    <span
                      style={{
                        width: '100px',
                        fontSize: '11px',
                        textAlign: 'center',
                      }}
                      className="btn-edit2"
                    >
                      GRUPO DE EMPRESA
                    </span>
                  )}
                  {card.tipo_acesso === 3 && (
                    <span className="btn-edit2">PÚBLICO</span>
                  )}
                </div>
              )}
              {tipoFiltro !== 0 && card.flg_em_uso && (
                <div>
                  <span className="btn-edit2">Adicionado</span>
                </div>
              )}
            </ContainerCard>
          ))}
      </SectionCards>
      <ModalCard enableExcluir={enableExcluir} />
    </div>
  );
};

export default CardContent;
